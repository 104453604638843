<template>
  <button
    class="info-button"
    :class="[
      `info-button--${size}`,
      `info-button--${theme}`,
      {
        'info-button--disabled': disabled
      }
    ]"
    :disabled="disabled"
  >
    <i
      class="info-button__icon"
      :class="[
        icon,
        `info-button__icon--${iconSize || size}`,
        `info-button__icon--${theme}`
      ]"
    />
  </button>
</template>

<script lang="ts">
type Size = 'big' | 'medium' | 'small'
type Theme = 'primary' | 'secondary' | 'tertiary' | 'gradient' | 'transparent'

export default {
  name: 'ReflectIconButton',
  props: {
    icon: {
      type: String,
      required: true
    },
    size: {
      type: String as PropType<Size>,
      default: 'medium'
    },
    iconSize: {
      type: String as PropType<Size>,
      default: undefined
    },
    theme: {
      type: String as PropType<Theme>,
      default: 'primary'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.info-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $bg-primary;
  border-radius: 8px;
  cursor: pointer;

  &--small {
    height: 24px;
    width: 24px;
  }

  &--medium {
    height: 28px;
    width: 28px;
  }

  &--big {
    height: 40px;
    width: 40px;
  }

  &--gradient {
    background: $bg-ai-gradient;
  }

  &--transparent {
    border: 1px solid $border-quinary;
    background: none;
    transition: all $short-transition ease;

    &:hover {
      background-color: $bg-tertiary;
      color: $text-inverse;
    }
  }

  &--disabled {
    cursor: default;
  }

  &__icon {
    @include font-icon;

    &--small {
      @include font-size($font-size-normal);
    }

    &--medium {
      @include font-size($font-size-intermediate);
    }

    &--big {
      @include font-size(30px);
    }

    &--primary {
      color: $button-secondary;
    }

    &--secondary {
      color: $link-secondary;
    }

    &--gradient {
      color: $text-inverse;
    }
  }
}
</style>
